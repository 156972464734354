@font-face {
font-family: '__neutra2Text_1cd59b';
src: url(/_next/static/media/55446484c5f9f70c-s.p.otf) format('opentype');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__neutra2Text_1cd59b';
src: url(/_next/static/media/bb3dd4ea961940b7-s.p.otf) format('opentype');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: '__neutra2Text_1cd59b';
src: url(/_next/static/media/1591f6da533b242e-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__neutra2Text_1cd59b';
src: url(/_next/static/media/419a5b81a2bca5a8-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__neutra2Text_1cd59b';
src: url(/_next/static/media/11d8e4b216e36dae-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__neutra2Text_1cd59b';
src: url(/_next/static/media/757ef6483b88454d-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__neutra2Text_1cd59b';
src: url(/_next/static/media/cf06c3916d365b16-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__neutra2Text_1cd59b';
src: url(/_next/static/media/f5dfe03410e53a71-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__neutra2Text_Fallback_1cd59b';src: local("Arial");ascent-override: 83.25%;descent-override: 23.48%;line-gap-override: 21.35%;size-adjust: 93.70%
}.__className_1cd59b {font-family: '__neutra2Text_1cd59b', '__neutra2Text_Fallback_1cd59b', Geneva, Verdana, sans-serif
}

@font-face {
font-family: '__tiemposHeadline_912bd2';
src: url(/_next/static/media/a38d1e86292212ef-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__tiemposHeadline_912bd2';
src: url(/_next/static/media/ad3d6f1d0b0df35e-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: italic;
}@font-face {font-family: '__tiemposHeadline_Fallback_912bd2';src: local("Arial");ascent-override: 78.92%;descent-override: 21.87%;line-gap-override: 20.16%;size-adjust: 99.22%
}.__className_912bd2 {font-family: '__tiemposHeadline_912bd2', '__tiemposHeadline_Fallback_912bd2', Times New Roman, Times, serif
}

